import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Input, Button, Row, Col, Form, InputNumber, message } from 'antd';
//import { UserOutlined } from '@ant-design/icons';
import { ReactComponent as BookmarkIcon } from 'assets/BookmarkIcon.svg';
import AntTabs from './TabsCointainer.style';
import format from 'date-fns/format';
import { getReservaInfo } from 'services/http';
import ePayco from 'services/Epayco';
import { APP_CODE, APP_KEY, ENV } from 'services/Paymentez';

const { TabPane: AntTabPane } = Tabs;

const TabsContainer = (props) => {
  const history = useHistory();
  const { idprsvp, handleSede } = props;
  const [idreserva, setIdreserva] = useState();
  const [arrayInfo, setArrayInfo] = useState({});
  const [messageValid, setMessageValid] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [inputProcess, setInputProcess] = useState(false);
  const [position, setPosition] = useState('1');
  const [tabdisabled1, setTabdisabled1] = useState(false);
  const [tabdisabled2, setTabdisabled2] = useState(true);
  const [loadingValue, setLoadingValue] = useState(false);
  const [form] = Form.useForm();
  const [dataDay, setDataDay] = useState('');
  const [timeStart, setTimeStar] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [minToPay, setminToPay] = useState(20000);
  const [currentSymbol, SetCurrentSymbol] = useState('COP');

  useEffect(() => {
    const reserva = idprsvp;
    if (reserva) {
      validarId(reserva);
    }
  }, [idprsvp]);

  const validarId = (values) => {
    setLoadingValue(true);
    setInputProcess(true);
    if (values.idReserva) {
      setTimeout(() => {
        setIdreserva(values.idReserva);
        obtenerInforsvp({ idreserva: values.idReserva });
        //console.log('ingresando por input');
      }, 1500);
    } else {
      setTimeout(() => {
        setIdreserva(values);
        obtenerInforsvp({ idreserva: values });
      }, 1500);
    }
  };

  const obtenerInforsvp = async (reserva) => {
    try {
      const resquestdata = await getReservaInfo(reserva);
      const { cod } = resquestdata;
      if (cod === 1000) {
        const { data } = resquestdata;
        //console.log(data);
        if (data.noMatch === true) {
          setMessageError(true);
          setMessageValid(false);
          setInputProcess(false);
          setLoadingValue();
        } else {
          setInputProcess(false);
          setMessageError(false);
          setArrayInfo(data);
          setminToPay(data.minPayment);
          if (data.centropais === 2) {
            //min monto para ecuador
            SetCurrentSymbol('USD');
          }
          formatDate(data);
          const { sedenombre, sedelogo } = data;
          handleSede(sedenombre, sedelogo);
          setMessageValid(false);
          changeTab(2);
          setTabdisabled1(true);
          setTabdisabled2(false);
          setLoadingValue();
        }
      } else {
        setInputProcess(false);
        setMessageError(false);
        setLoadingValue();
        setMessageValid(true);
      }
    } catch (error) {
      message.error('Ocurrió un error inesperado, probablemente sea la conexión. Intena más tarde');
      setLoadingValue(false);
    }
  };
  /**** Dator para fecha , hora de la reserva */
  const formatDate = (data) => {
    const day = format(new Date(data.rsvpstart), 'dd / MMM / uuu');
    const timersvpstart = format(new Date(data.rsvpstart), 'hh:mm aa');
    const timetimersvpend = format(new Date(data.rsvpend), 'hh:mm aa');
    setDataDay(day);
    setTimeStar(timersvpstart);
    setTimeEnd(timetimersvpend);
  };

  const pagoReserva = (values) => {
    setLoadingValue(true);
    setTimeout(() => {
      setLoadingValue(false);
    }, 5000);

    const { sedenombre, nombrecancha, centropais } = arrayInfo;
    const { montoPagar } = values;

    // detectar el pais
    switch (centropais) {
      case 2:
        /*         message.warning('Esta reserva no puede ser pagada por los momentos ya que es de Ecuador, proximamente podrás hacerlo sin problemas');
        setLoadingValue(false);
        borrar();
        break; */
        //Ecuador -> Paymentez
        const paymentezData = {
          user_id: idreserva,
          user_email: '',
          order_description: `Reserva en ${nombrecancha} (${sedenombre}) de ${timeStart} - ${timeEnd}`,
          order_amount: parseFloat(montoPagar),
          order_vat: 0,
          order_reference: idreserva,
          order_tax_percentage: 0,
          //order_installments_type: 2, // optional: The installments type are only available for Ecuador and Mexico. The valid values are: https://paymentez.github.io/api-doc/#payment-methods-cards-debit-with-token-base-case-installments-type
          //order_taxable_amount: 0, // optional: Only available for Ecuador. The taxable amount, if it is zero, it is calculated on the total. Format: Decimal with two fraction digits.
          //order_tax_percentage: 10 // optional: Only available for Ecuador. The tax percentage to be applied to this order.
        };

        // Instance Paymentez
        const paymentezCheckout = new window.PaymentCheckout.modal({
          client_app_code: APP_CODE, // Client Credentials
          client_app_key: APP_KEY, // Client Credentials
          locale: 'es', // User's preferred language (es, en, pt). English will be used by default.
          env_mode: ENV, // `prod`, `stg`, `local` to change environment. Default is `stg`
          onOpen: function () {
            console.log('modal open');
          },
          onClose: function () {
            console.log('modal closed');
          },
          onResponse: function (response) {
            // The callback to invoke when the Checkout process is completed
            if (response.transaction) {
              const { transaction } = response;
              console.log({ transaction });
              history.push('/confirm/response?gateway=2', transaction);
            } else {
              console.log({ response });
            }
          },
        });
        paymentezCheckout.open(paymentezData);
        break;

      default:
        // Colombia -> ePayco
        const paycoData = {
          //Parametros compra (obligatorio)
          name: `Pago de reserva iCancha ${idreserva}`,
          description: `Reserva en ${nombrecancha} (${sedenombre}) de ${timeStart} - ${timeEnd}`,
          currency: 'cop',
          amount: parseFloat(montoPagar),
          country: 'co',
          lang: 'es',
          external: 'false',
          //Atributos opcionales
          extra1: idreserva,
          confirmation: 'https://api-rest.icancha.com/utilities/gateway/payments/epayco/confirm',
          response: 'https://pagos.icancha.com/confirm/response?gateway=1',
          //Atributos cliente
          //atributo deshabilitación metodo de pago
          //methodsDisable: ['TDC', 'PSE', 'SP', 'CASH', 'DP'],
        };
        ePayco.open(paycoData);
        break;
    }
  };

  const changeTab = (key) => {
    if (key === '1') {
      return;
    } else {
      setPosition('2');
    }
  };

  const borrar = () => {
    setPosition('1');
    setIdreserva();
    form.resetFields();
    setTabdisabled1(false);
    setTabdisabled2(true);
    handleSede(null);
  };

  return (
    <AntTabs type='card' activeKey={position} onChange={(key) => changeTab(key)}>
      <AntTabPane tab='Paso 1' key='1' disabled={tabdisabled1}>
        <Form onFinish={validarId}>
          <Row className='RowTitle'>
            <Col>
              <h3>
                Ingresa a continuación <br />
                el <span>ID</span> de tu reserva
              </h3>
            </Col>
          </Row>
          <Row className='RowInputId'>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name='idReserva'
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el id de la Reserva!',
                  },
                  {
                    pattern: /^[a-zA-Z\s0-9]+$/,
                    message: 'No puede contener caracteres especiales',
                  },
                ]}>
                <Input placeholder='ID RESERVA' prefix={<BookmarkIcon />} disabled={inputProcess} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row className='RowButton'>
            <Button type='primary' htmlType='submit' loading={loadingValue}>
              Validar
            </Button>
            <Col className='ColMessage' md={24} sm={24} xs={24}>
              {messageValid && <p>No existe la reserva buscada, por favor ingrese una reserva valida</p>}
              {messageError && <p>Reserva no encontrada, verifica el número de reserva ingresado</p>}
            </Col>
          </Row>
        </Form>
      </AntTabPane>
      <AntTabPane tab='Paso 2' key='2' disabled={tabdisabled2}>
        <Form form={form} onFinish={pagoReserva}>
          {/*<Row className='RowTitleSede'>
            <Col className='ColAvatar' md={6} sm={6} xs={6}>
              <Avatar size={56} icon={<UserOutlined />} src={arrayInfo.centrologo} />
            </Col>
            <Col md={18} sm={18} xs={18}>
              <h4>{arrayInfo.centronombre}</h4>
            </Col>
              </Row>*/}
          <Row className='RowIdReserva'>
            <Col md={24} sm={24} xs={24}>
              <h3>Tu ID de Reserva es:</h3>
            </Col>
            <Col className='colIdReserva' md={24} sm={24} xs={24}>
              <BookmarkIcon />
              <p>{idreserva}</p>
              <Button type='link' danger onClick={(values) => borrar(values)}>
                Borrar
              </Button>
            </Col>
          </Row>
          <Row className='RowDate'>
            <Col md={7} sm={7} xs={7}>
              <h4>Fecha</h4>
            </Col>
            <Col md={10} sm={10} xs={10}>
              <h4>Hora</h4>
            </Col>
            <Col md={7} sm={7} xs={7}>
              <h4>Cancha</h4>
            </Col>
            <Col className='ColDate' md={7} sm={7} xs={7}>
              <p>{dataDay}</p>
            </Col>
            <Col className='ColCenter' md={10} sm={10} xs={10}>
              <p>{`${timeStart} - ${timeEnd}`}</p>
            </Col>
            <Col md={7} sm={7} xs={7}>
              <p>{arrayInfo.nombrecancha}</p>
            </Col>
          </Row>
          <Row className='RowPrice'>
            <Col className='ColAbono' md={12} sm={12} xs={12}>
              <h3>Valor Abonado</h3>
            </Col>
            <Col className='ColPrice ColAbono' md={12} sm={12} xs={12}>
              <span>
                {`$ ${arrayInfo.pagadoencentro}`.replace(/\d(?=(\d{3})+\.)/g, '$&,')} {currentSymbol}
              </span>
            </Col>
            <Col className='ColReserva' md={12} sm={12} xs={12}>
              <h3>Valor de la Reserva</h3>
            </Col>
            <Col className='ColPrice ColReserva' md={12} sm={12} xs={12}>
              <span>
                {`$ ${arrayInfo.tarifahorarsvp}`.replace(/\d(?=(\d{3})+\.)/g, '$&,')} {currentSymbol}
              </span>
            </Col>
          </Row>
          {arrayInfo.pagadoencentro === arrayInfo.tarifahorarsvp ? (
            <Row>
              <Col
                md={24}
                sm={24}
                xs={24}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                }}>
                <p style={{ marginBottom: '0' }}>La reserva ya fue pagada en su totalidad.</p>
              </Col>
            </Row>
          ) : (
            <>
              <Row className='RowInputMonto'>
                <Col md={24} sm={24} xs={24}>
                  <h3>Valor a Pagar</h3>
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item
                    name='montoPagar'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingresa el valor a Pagar! ',
                      },
                      {
                        type: 'number',
                        min: minToPay,
                        message: `El monto mínimo a pagar es de $${minToPay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
                      },
                    ]}>
                    <InputNumber
                      placeholder='0.00'
                      max={parseFloat(arrayInfo.porpagarencentro)}
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col className='ColMessage' md={24} sm={24} xs={24}>
                  <p>
                    Al hacer click en Pagar aceptas los{' '}
                    <span>
                      Términos y <br />
                      Condiciones
                    </span>{' '}
                    y <span>Políticas de Privacidad</span> de iCancha
                  </p>
                </Col>
              </Row>
              <Row className='RowButtonSuccess'>
                <Button type='primary' htmlType='submit' loading={loadingValue}>
                  Pagar
                </Button>
              </Row>
            </>
          )}
        </Form>
      </AntTabPane>
    </AntTabs>
  );
};

export default TabsContainer;

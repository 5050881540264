import axios from 'axios';
const utilitiesURL = process.env.NODE_ENV === 'production' ? 'https://api-rest.icancha.com/utilities' : 'http://localhost:8080/utilities';

const headers = { 'Content-Type': 'application/json' };

export const getReservaInfo = async ({ idreserva }) => {
  try {
    const destination = `${utilitiesURL}/gateway/reservas/${idreserva}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
      headers: headers,
    });
    const {
      data: { data, cod },
    } = connection;
    switch (cod) {
      case 1000:
        return { cod, data };
      default:
        return { cod };
    }
  } catch (error) {
    console.log({ getReservaInfo: error });
  }
};

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Card from 'components/UI/Card';
import TabsContainer from 'components/TabsContainer';

const Home = () => {
  const [idprsvp, setIdprsvp] = useState(null);
  const [sedeData, setSedeData] = useState(null)

  // de este objeto sacas el idprsvp
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const reserva = location.search.split('?')[1].split('&')[0];
      if (reserva) {
        const a = reserva.split('=');
        if (a[0] === 'idprsvp') {
          setIdprsvp(a[1]);
        }
        return;
      }
    }
  }, [location]);

  const handleSedeData = (sedenombre, sedelogo) => {
    if (!sedenombre || !sedelogo) {
      setSedeData(null);
    } else {
      setSedeData({
        sedenombre,
        sedelogo,
      });
    }
  };

  return (
    <Card sedeData={sedeData}>
      <TabsContainer idprsvp={idprsvp} handleSede={handleSedeData} />
    </Card>
  );
};

export default Home;

import axios from 'axios';

const PUBLIC_K = '62573fed8f09c61efab56a64b00443ab';
const TEST = false;

const ePayco = window.ePayco.checkout.configure({
  key: PUBLIC_K,
  test: TEST,
});

const PAYCO_API = 'https://secure.epayco.co/validation/v1/reference/';

export const requestTransaction = async (refpayco) => {
  try {
    const destination = `${PAYCO_API}${refpayco}`;
    const connection = await axios({
      method: 'GET',
      url: destination,
    });
    const { data } = connection;
    const { success } = data;
    if (success) {
      const { data: transaccion } = data;
      return transaccion;
    }
  } catch (error) {
    console.log({ requestTransaction: error });
  }
};

export default ePayco;

import React, { useEffect, useState } from 'react';
import { Card as AntdCard } from 'antd';
import TitleCard from "../TitleCard";

const Card = ({ children, sedeData }) => {
  const [sedeNombre, setSedeNombre] = useState(null);
  const [sedeLogo, setSedeLogo] = useState(null);

  useEffect(() => {
    //console.log({ sedeData });
    if (sedeData !== null && sedeData !== undefined) {
      const { sedenombre, sedelogo } = sedeData;
      setSedeLogo(sedelogo);
      setSedeNombre(sedenombre);
    }
  }, [sedeData]);

  return (
    <AntdCard
      headStyle={{
        fontSize: '22px',
        fontWeight: '700',
        color: '#1b1464',
        fontFamily: '"Roboto", sans-serif',
        border: '0px',
        textAlign: 'center',
        padding: '30px 0px 10px', 
      }}
      bodyStyle={{
        background: '#FFFFFF',
        padding: ' 0px',
        borderRadius: '10px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        minHeight: '200px',
      }}
      style={{
        width: 400,
        background: 'transparent',
        border: 0,
        margin: '0px 8px 20px',
      }}
      title={<TitleCard sedeData={sedeData} sedeLogo={sedeLogo} sedeNombre={sedeNombre} />}>
      {children}
    </AntdCard>
  );
};

export default Card;

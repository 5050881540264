import React from 'react';
import { Row, Col, Avatar } from 'antd';
import { ReactComponent as LogoHorizontal } from '../assets/LogoHorizontal.svg';


const TitleCard = ( {sedeData, sedeLogo, sedeNombre} ) =>  {
        return (
            <>
            {sedeData ? (
                <>
                <Row style={{ justifyContent: 'center' }}> 
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign: "-webkit-center", marginBottom: "15px" }}>
                        <div style={{ width: "81px", height:"81px", borderRadius: "50%", background: "linear-gradient(to bottom,#421b88 18.23%,#FF1744 100%)"}}>
                            <Avatar size={77} style={{background: "white" , marginTop: "2px" }} src={sedeLogo} />
                        </div>
                    </Col>
                    <Col>
                        <p style={{ marginBottom: '0', textAlign: 'center', lineHeight: 'initial', whiteSpace: "normal"}}>
                            Centro Deportivo {sedeNombre}
                        </p>
                    </Col>
                </Row>
                </>
                ) : (
                <Row style={{ justifyContent: 'center' }}> 
                    <Col style={{ width: '100%' }}>
                        <LogoHorizontal />
                    </Col>
                    <p style={{ marginBottom: '0', textAlign: 'center', lineHeight: 'initial' }}>
                    Sistema de gestión de pagos <br />
                    iCancha
                    </p>
                </Row>
                )}
        </>
        );
};

export default TitleCard;
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from 'components/Layout';
import Home from 'pages/Home';
import Response from 'pages/Response';
import NotFound from 'pages/NotFound';

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/confirm/response' component={Response} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Skeleton, Result, Button } from 'antd';
import Card from 'components/UI/Card';
import { requestTransaction } from 'services/Epayco';

const Response = () => {
  const location = useLocation();
  const history = useHistory();
  const [gateway, setGateway] = useState(null);
  const [transactionData, setTransactionData] = useState(null);

  useEffect(() => {
    if (location.search) {
      const query = location.search;
      const detectedGateway = query.split('?')[1].split('&')[0].split('=')[1];
      if (detectedGateway) {
        setGateway(detectedGateway);
        if (detectedGateway === '1') {
          let refPayco;
          try {
            refPayco = query.split('?')[1].split('&')[1].split('=')[0] === 'ref_payco' ? query.split('?')[1].split('&')[1].split('=')[1] : false;
          } catch (error) {
            history.push('/');
          }
          if (refPayco) {
            const getTransaction = async () => {
              const transaction = await requestTransaction(refPayco);
              if (!transaction) {
                history.push('/');
              } else {
                setTransactionData(transaction);
              }
            };
            getTransaction();
          } else {
            history.push('/');
          }
        } else {
          // Es paymentez
          console.log({ location });
          if (location.state) {
            // viene con data previa y solo viene con la transacción el error se maneja en el paso previo
            const { state: transaction } = location;
            setTransactionData(transaction);
          } else {
            history.push('/');
          }
        }
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }, [location]);

  const TransaccionPayco = () => {
    const status = transactionData.x_cod_response === 1 ? 'success' : transactionData.x_cod_response === 3 ? 'info' : 'error';
    const title = transactionData.x_cod_response === 1 ? 'Pago agregado exitosamente a la reserva' : transactionData.x_cod_response === 3 ? 'Pago en espera de ser procesado' : 'Transacción Rechazada';
    const subTitle =
      transactionData.x_cod_response === 1
        ? `ID Reserva: ${transactionData.x_extra1}, referencia del pago ${transactionData.x_ref_payco}, monto: ${new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
            transactionData.x_amount
          )}`
        : transactionData.x_cod_response === 3
        ? `ID Reserva: ${transactionData.x_extra1}, referencia del pago ${transactionData.x_ref_payco}, monto: ${new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(
            transactionData.x_amount
          )}`
        : `El pago no se pudo agregar a la reserva on ID: ${transactionData.x_extra1}`;

    //
    return (
      <div style={{ margin: '15px' }}>
        <Result
          status={status}
          title={title}
          subTitle={subTitle}
          extra={[
            <Button type='primary' key='console' onClick={() => history.push('/')}>
              Volver
            </Button>,
          ]}
        />
      </div>
    );
  };

  const TransaccionPaymentez = () => {
    const status = transactionData.status_detail === 3 ? 'success' : transactionData.status_detail === 0 ? 'info' : 'error';
    const title = transactionData.status_detail === 3 ? 'Pago agregado exitosamente a la reserva' : transactionData.status_detail === 0 ? 'Pago en espera de ser procesado' : 'Transacción Rechazada';
    const subTitle =
      transactionData.status_detail === 3
        ? `ID Reserva: ${transactionData.dev_reference}, referencia del pago ${transactionData.id}, monto: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            transactionData.amount
          )}`
        : transactionData.status_detail === 0
        ? `ID Reserva: ${transactionData.dev_reference}, referencia del pago ${transactionData.id}, monto: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            transactionData.amount
          )}`
        : `El pago no se pudo agregar a la reserva on ID: ${transactionData.dev_reference}`;
    return (
      <div style={{ margin: '15px' }}>
        <Result
          status={status}
          title={title}
          subTitle={subTitle}
          extra={[
            <Button type='primary' key='console' onClick={() => history.push('/')}>
              Volver
            </Button>,
          ]}
        />
      </div>
    );
  };

  return (
    <Card>
      {!transactionData && (
        <div style={{ margin: '15px' }}>
          <Skeleton active />
        </div>
      )}
      {transactionData && gateway === '1' && TransaccionPayco()}
      {transactionData && gateway === '2' && TransaccionPaymentez()}
    </Card>
  );
};

export default Response;

import React from 'react';
import styled from 'styled-components';
import { Layout as AntdLayout } from 'antd';
import { Row, Col } from 'antd';
import { ReactComponent as Colombia } from '../../assets/Colombia.svg';
import { ReactComponent as Ecuador } from '../../assets/Ecuador.svg';
//import imagen from '../../assets/Group157.png';
import imagen from '../../assets/Group161.png';
import { ReactComponent as LogoHorizontal } from '../../assets/LogoHorizontal.svg';

const { Footer: AntdFooter } = AntdLayout;

const FooterAntd = styled(AntdFooter)`
  background-color: #EDEDED;
  padding: 0px;


  .gradiente-border {
    width: 100%;
    height: 2px;
    display:flex;
    background-image: linear-gradient(to right, #421B88 0% , #FF1744 100%);
  }

  .row-footer-first {
    height: 100px; //150px
  }

  .col-motto-text {
    background: #EDEDED;
    display: flex;
    align-items: center;    
  }
  .col-motto-text-logo {
    margin: 0 0 0 40px;
  }
  .col-motto-text p {
    font-weight: 700;
    font-size: 16px;
    font-style: Bold; 
    margin: 0px 0px 0px 16px;
  }

  .col-payment-gateways {
    background: #ededed;
    display: flex;
    justify-content: center;
    flex-direction: column;

  }

  .col-payment-gateways p {
    color: #1b1464;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px;
  }
  .col-payment-gateways img {
    //margin: 0 50px 0 45px;
   // margin: 0px 40px;
  }

  .row-footer-last {
    height: 50px;
  }

  .col-flags {
    display: flex;
    justify-content: flex-end ; //start;
    align-items: center;
    background: #ededed;
  }

  .col-flags p {
    color: #1B1464;
    margin: 0 0 0 10px;
    font-weight: bold;
  }

  .col-flags .text-right{
    margin-Right: 40px;
  }

  .col-flags .flags {
    margin: 0 0 0 25px;
  }

  .col-flags .flags:first-child {
    margin: 0;
  }

  .col-copyrights {
    background: #1B1464;
    display: flex;
    align-items: center;
    justify-content: center ;
    height: 30px;
  }

  .col-copyrights p {
    margin: 0 50px 0 50px;
    color: white;
    font-weight: bold;
  }

  @media (max-width: 840px){
    .col-motto-text-logo {
      margin: 0 0 0 10px;
    }
    .col-motto-text p {
      margin: 0px 0px 0px 5px;
    }

    .col-flags .text-right{
      margin-Right: 10px;
    }
  }


  @media (max-width: 576px) {
    background: #DFE3E8;
    .row-footer-first { 
      height: auto;
    }
    .col-motto-text {
      justify-content: center;
      margin-left: 0px;
      height: 100px;
    }
    .col-motto-text-logo {
      margin-left: 0px ;
      visibility: hidden;
    }
    .col-motto-text p {
      margin-left: -44px;
      text-align: center;
    }
    .col-payment-gateways {
      background: #DFE3E8;
      height: 90px;
      align-items: center;
    }
    .col-payment-gateways img {
      width: 250px;
    }
    .border-div {
        width: 90%;
        height: 2px;
        display:flex;
        background: #BEC3CC;
        margin: 0px 20px;
    }

    .col-flags {
      justify-content: center;
      background: #DFE3E8;
      height: 80px;
    }
    .col-copyrights {
      height: 49px;
    }
  }
`;

const Footer = () => {
  return (
    <FooterAntd>
      <Row className="row-footer-first">
        <Col className="col-motto-text" xs={24} sm={9} md={9} lg={9} xl={10} xxl={10}>
          <LogoHorizontal className="col-motto-text-logo"/>
          <p style={{ color:"#1b1464",fontStyle: "bold", fontSize: "16px" }}>
            Realiza tus reservas con iCancha<br/>
            <span style={{color: "red"}}>#</span>RápidoFácilyGol
          </p>
        </Col>
        <Col className="col-payment-gateways" xs={24} sm={6} md={6} lg={6} xl={4} xxl={4}>
          <p>Procesamos tus pagos con:</p>
          <img src={imagen} alt='payment methods' />
        </Col>
        <div className="border-div"></div>
        <Col  className='col-flags' xs={24} sm={9} md={9} lg={9} xl={10} xxl={10}>
          <Colombia className='flags' />
          <p>Colombia </p>
          <Ecuador className='flags' />
          <p className="text-right">Ecuador</p>
        </Col>
      </Row>
      <div className="gradiente-border"></div>
      <Row >
        <Col  className="col-copyrights" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <p>&#169; iCancha {new Date().getFullYear()}</p>
        </Col>
      </Row>
      {/*<Row className='row-footer-first'>
        <Col className='col-motto-text' xs={24} sm={12} md={14} lg={16} xl={18} xxl={20}>
          <p>
            Realiza tus reservas con <br />
            iCancha <span>#</span> RápidoFácilyGol
          </p>
        </Col>
        <Col className='col-payment-gateways' xs={24} sm={12} md={10} lg={8} xl={6} xxl={4}>
          <p>Procesamos tus pagos con:</p>
          <img src={imagen} alt='payment methods' />
        </Col>
      </Row>
      <Row className='row-footer-last'>
        <Col className='col-flags' sm={12} md={10} lg={8} xl={6} xxl={4}>
          <Colombia className='flags' />
          <p>Colombia </p>
          <Ecuador className='flags' />
          <p>Ecuador</p>
        </Col>
        <Col className='col-copyrights' xs={24} sm={12} md={14} lg={16} xl={18} xxl={20}>
          <p>Copyrights - iCancha {new Date().getFullYear()}</p>
        </Col>
  </Row>*/}
    </FooterAntd>
  );
};

export default Footer;

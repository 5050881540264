import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
//import Header from './Header';
import Footer from './Footer';

const { Content: ContentAntd } = AntLayout;

const Content = styled(ContentAntd)`
  display: flex;
  justify-content: center;
  //min-height: calc(100vh - 212px);
  min-height: calc(100vh - 132px);
  @media (max-width: 576px) {
    min-height: calc(100vh - 140px);
    .ant-card-head {
      padding: 25px 0px 25px !important;
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title> Pasarela de pagos | iCancha </title>
        <meta name='description' content='Paga las reservas que hagas en los sedes deportivas de manera fácil y segura' />
      </Helmet>
      <AntLayout
        style={{
          background: 'linear-gradient(180deg, #EBEFF3 0%, #FFFFFF 100%)',
        }}>
        {/*<Header /> */}
        <Content>{children}</Content>
        <Footer />
      </AntLayout>
    </>
  );
};

export default Layout;

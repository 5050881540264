import styled from "styled-components";
import { Tabs } from "antd";

const AntTabs = styled(Tabs)`
  .ant-tabs {
    border-radius: 10px !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border: 0 !important;
  }
  .ant-tabs-nav-wrap {
    border-radius: 10px 10px 0 0;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tab {
    width: 50%;
    margin: 0px !important;

    font-family: "Roboto", sans-serif;
    color: #a9a9af;
    border: 0 !important;
    height: 51px;
    display: flex;
    justify-content: center;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 !important;
  }

  .ant-tabs-tab-active {
    background: #3742fa !important;
    border-radius: 0 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffff !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border: 0 !important;

    display: flex;
    justify-content: center;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 !important;
    border: 0 !important;
  }

  h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  h4 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 12px;
    margin: 0;
  }

  .RowTitle {
    margin: 20px 0px;
    .ant-col {
      width: 100%;
    }
    h3 {
      line-height: 16px;
      margin: 15px 0;
      text-align: center !important;
    }
    span {
      font-family: "Roboto", sans-serif;
      color: #8a2be2;
    }
  }

  .RowTitleSede {
    align-items: center;
    margin: 20px 10px;
    .ColAvatar {
      text-align: center;
    }
  }

  .RowInputId {
    margin: 15px 24px;
    .ant-input {
      color: #595959;
      font-family: "Roboto", sans-serif;
    }

    .ant-input-affix-wrapper {
      border-radius: 10px;
      height: 40px;
      font-family: "Roboto", sans-serif;
      width: 100%;
    }
  }

  .RowIdReserva {
    margin: 15px 24px;
    .colIdReserva {
      border: 1px solid #e9e9e9;
      border-radius: 10px;
      height: 40px;
      display: flex;
      align-items: center;
    }
    p {
      font-family: "Roboto", sans-serif;
      color: #595959;
      margin: 0px;
      width: 65%;
    }
    svg {
      margin: 10px;
    }
  }

  .RowDate {
    text-align: center;
    background: #fafafa;
    padding: 14px 17px;
    h4 {
      margin: 0;
    }
    p {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: #858689;
      margin: 0;
      padding: 3px 2px;
    }
    .ColCenter {
      border-right: 1px solid #cacaca;
      border-left: 1px solid #cacaca;
    }
  }

  .RowPrice {
    h3 {
      margin: 0px;
    }
    box-shadow: 0px 14px 20px rgb(0 0 0 / 4%);
    padding: 15px 24px;
    .ColAbono {
      border-bottom: 1px solid #d6d6d6;
      padding-bottom: 8px;
    }
    .ColPrice {
      text-align: right;
    }
    .ColReserva {
      margin-top: 8px;
    }
    span {
      font-weight: 700;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      color: #00e676;
    }
  }

  .RowInputMonto {
    background: #fafafa;
    padding: 24px;
    margin-top: 5px;

    .ant-input-number {
      color: #595959;
      font-family: "Roboto", sans-serif;
      border-radius: 10px;
      height: 40px;
      width: 100%;
    }

    .ant-input-affix-wrapper {
      border-radius: 10px;
      height: 40px;
      font-family: "Roboto", sans-serif;
    }

    .ant-input-number-input {
      height: 38px;
      width: 100% !important;
    }

    .ant-input-number-handler-wrap {
      border-radius: 0 10px 10px 0;
    }
    .colInput {
      border: 1px solid #e9e9e9;
      border-radius: 10px;
      display: flex;
      align-items: center;
      height: 40px;
      background: #fff;
    }
  }

  .ColMessage {
    text-align: center;
    p{
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      color: #595959;
      margin: 0;
    }
    span{
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      color: #2569F8;
    }
    }
  }

  .RowButton {
    margin: 0 24px 40px;
    .ant-btn-primary {
      width: 100%;
      height: 40px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 700;
      box-shadow: 0 15px 13px -10px rgb(55 16 250 / 50%),
        0 1px 4px rgb(55 16 250 / 30%), 0 0 40px rgb(55 16 250 / 10%) inset;
      border-radius: 3px;
    }
    .ColMessage {
      display: flex;
      justify-content: center;
      p {
        color: red;
        font-family: "Roboto", sans-serif;
        margin: 5px 0 0;
      }
    }
  }
  .RowButtonSuccess {
    border-radius: 0 0 10px 10px;
    padding: 0 24px 40px;
    background: #fafafa;
    .ant-btn-primary {
      width: 100%;
      height: 40px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 700;
      background: #00e676;
      border-color: #00e676;
      box-shadow: 0 15px 13px -10px rgb(0 230 118 / 50%),
        0 1px 4px rgb(0 230 118 / 30%), 0 0 40px rgb(0 230 118 / 10%) inset;
      border-radius: 3px;
    }
  }

  @media (max-width: 576px) {
    .ColCenter{
      display: flex;
      align-items: center;
      justify-content: center;  
    }
    .ColDate {
      display: flex;
      align-items: center;
      justify-content: center;  
    }

  }
`;

export default AntTabs;
